import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalDQFCFlags } from 'src/app/shared/constants/GlobalDQFC.flags';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard  {
  constructor(
    private router: Router
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUnderMaintenance();
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUnderMaintenance();
  }

  private isUnderMaintenance(): boolean {
    const value = GlobalDQFCFlags.maintenance;
    if (value) {
      this.router.navigate(['/maintenance']);
    }
    return !value;
  }
}
