import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalDQFCConstants } from 'src/app/shared/constants/GlobalDQFC.constants';
import { CookieService } from '../../../shared/services/helper/cookie.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() hidden?= false;
  dataType = '1x-header-unauth';
  loginDestinationUrl = '';

  constructor(
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    const currentUrl = window.location.href;
    this.loginDestinationUrl = encodeURIComponent(currentUrl);
    const sso = this.cookieService.getOktaJWT();
    if (sso !== '') {
      this.dataType = '1x-header-auth';
    } else {
      this.dataType = '1x-header-unauth';
    }
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    window[GlobalDQFCConstants.oneX].addElement(document.querySelector('#header'));
  }

  ngOnDestroy(): void {
    // @ts-ignore
    window[GlobalDQFCConstants.oneX].removeElement(document.querySelector('#header'));
  }
}