import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RouteParamsService } from 'src/app/routing/service/route-params.service';
import { ValidatorService } from 'src/app/shared/services/helper/validator.service';

@Injectable({
  providedIn: 'root'
})
export class QueryparamsGuard  {
  constructor(
    private routeParamsService: RouteParamsService,
    private validatorService: ValidatorService
  ) { }

  //regExClmId = '^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$';
  //regExParId = '^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$';
  regExClmId = '^[a-zA-Z0-9-]+$';
  regExParId = '^[a-zA-Z0-9-]+$';
  regCmpId = '^[a-zA-Z0-9:-]+$';

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const extClmId: string = route.queryParams['claimid'];
    const extparId: string = route.queryParams['participantid'];
    const cmpId: string = route.queryParams['cmpid'];

    if (this.validatorService.checkNotEmpty(extClmId) &&
      this.validatorService.checkNotEmpty(extparId) &&
      this.validatorService.checkNotEmpty(cmpId)) {
      const status = this.validatorService.checkPattern(extClmId.trim(), this.regExClmId) &&
        this.validatorService.checkPattern(extparId.trim(), this.regExParId) &&
        this.validatorService.checkPattern(cmpId.trim(), this.regCmpId);
      return of(status);
    } else {
      this.routeParamsService.navigate('/technical-error');
      return of(false);
    }
  }
}
